import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import cls from './adminslides.module.scss'
import slideTills from '@/shared/assets/slideTills.png'
import slideTags from '@/shared/assets/slideTags.png'
import slideDivices from '@/shared/assets/slideDivices.png'
import { classNames } from '@/shared/lib/utils/classNames'
import { cacheImages } from '@/shared/lib/utils/cacheImages'
export const AdminSlides = ({ className }) => {
    const [slide, setSlide] = useState([
        <img
            alt="slideTills"
            src={slideTills}
        />,
        <img
            alt="slideTags"
            src={slideTags}
        />,
        <img
            alt="slideDivices"
            src={slideDivices}
        />,
        <img
            alt="slideTills"
            src={slideTills}
        />,
        <img
            alt="slideTags"
            src={slideTags}
        />,
        <img
            alt="slideDivices"
            src={slideDivices}
        />,
        <img
            alt="slideTills"
            src={slideTills}
        />,
        <img
            alt="slideTags"
            src={slideTags}
        />,
        <img
            alt="slideDivices"
            src={slideDivices}
        />,
        <img
            alt="slideTills"
            src={slideTills}
        />,
        <img
            alt="slideTags"
            src={slideTags}
        />,
        <img
            alt="slideDivices"
            src={slideDivices}
        />,
        <img
            alt="slideTills"
            src={slideTills}
        />,
        <img
            alt="slideTags"
            src={slideTags}
        />,
        <img
            alt="slideDivices"
            src={slideDivices}
        />,
        <img
            alt="slideTills"
            src={slideTills}
        />,
        <img
            alt="slideTags"
            src={slideTags}
        />,
        <img
            alt="slideDivices"
            src={slideDivices}
        />,
    ])

    const [current, setCurrent] = useState(1)
    const [translateX, setTranslateX] = useState(0)
    const sliderRef = useRef()
    const intervalRef = useRef(null)

    const actionHandler = useCallback(
        (mode) => {
            sliderRef.current.style.transitionDuration = '300ms'
            if (mode === 'prev') {
                if (current <= 1) {
                    setTranslateX(0)
                    setCurrent(slide.length)
                } else {
                    setTranslateX(sliderRef.current.children[0].clientWidth * (current - 1))
                    setCurrent((prev) => --prev)
                }
            } else if (mode === 'next') {
                if (current >= slide.length) {
                    setTranslateX(sliderRef.current.children[0].clientWidth * (slide.length + 1))
                    setCurrent(1)
                } else {
                    setTranslateX(sliderRef.current.children[0].clientWidth * (current + 1))
                    setCurrent((prev) => ++prev)
                }
            }
        },
        [current, slide]
    )

    useEffect(() => {
        const transitionEnd = () => {
            if (current <= 1) {
                sliderRef.current.style.transitionDuration = '0ms'
                setTranslateX(sliderRef.current.children[0].clientWidth * current)
            }

            if (current >= slide.length) {
                sliderRef.current.style.transitionDuration = '0ms'
                setTranslateX(sliderRef.current.children[0].clientWidth * slide.length)
            }
        }

        document.addEventListener('transitionend', transitionEnd)

        return () => {
            document.removeEventListener('transitionend', transitionEnd)
        }
    }, [current, slide])

    useEffect(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current)
        }
        intervalRef.current = setInterval(() => {
            actionHandler('next')
        }, 5000)

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current)
            }
        }
    }, [actionHandler])

    const slides = useMemo(() => {
        if (slide.length > 1) {
            let items = slide.map((item, index) => (
                <div
                    id={item.index}
                    className={classNames(cls.slide, [], {
                        [cls.active]: index === current - 1,
                    })}
                >
                    {item}
                </div>
            ))

            return [
                <div
                    key={slide.length + 1}
                    className={cls.slide}
                >
                    {slide[slide.length - 1]}
                </div>,
                ...items,
                <div
                    key={slide.length + 2}
                    className={cls.slide}
                >
                    {slide[0]}
                </div>,
            ]
        }

        return <div className={cls.slide}>{slide[0]}</div>
    }, [slide, current])

    useLayoutEffect(() => {
        setTranslateX(sliderRef.current.children[0].clientWidth * current)
    }, [])
    return (
        <div className={classNames(cls.test, [className])}>
            <section className={cls.Root}>
                <div
                    ref={sliderRef}
                    className={cls.Container}
                    style={{
                        transform: `translate3D(${-translateX}px, 0px, 0px)`,
                    }}
                >
                    {slides}
                </div>
            </section>
        </div>
    )
}
