import image1 from '@/shared/assets/beagraphics.png'
import image2 from '@/Mobile/shared/assets/buildBus.png'
import image3 from '@/shared/assets/startDistr.webp'
import image4 from '@/shared/assets/getFun.webp'
import image5 from '@/shared/assets/onclick.png'
import image6 from '@/shared/assets/slideDivices.png'
import image7 from '@/shared/assets/slideDivices.png'
import video from '@/shared/assets/videoW.webm'

export const images = [image1, image2, image3, image4, image5, image6, image7, video]
