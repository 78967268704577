import React, { useCallback, useEffect, useRef, useState } from 'react'
import bgImg from '@/shared/assets/bg_stars.png'
import logo from '@/shared/assets/logo_stellar_bet.webp'
import { MobileMenu } from '../widgets/MobileMenu/MobileMenu'
import cls from './MobileMain.module.scss'
import { useLocation, useNavigate } from 'react-router-dom'
import { ContactForm } from '@/widgets/ContactForm/ContactForm'
import { MobileHome } from '../widgets/MobileHome/MobileHome'
import { MobileAbout } from '../widgets/MobileAbout/MobileAbout'
import { MobileFeatures } from '../widgets/MobileFeatures/MobileFeatures'
import { MobileBusiness } from '../widgets/MobileBusiness/MobileBusiness'
import { MobileDistr } from '../widgets/MobileBecomeDistr/MobileDistr'
import { images } from './imagesSrc'

export const MobileMain = ({ children }) => {
    const [open, setOpen] = useState(false)
    const rootRef = useRef()
    const location = useLocation()
    const [current, setCurrent] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const homeElement = document.getElementById('mobilehome')
    const aboutElement = document.getElementById('mobileabout')
    const featuresElement = document.getElementById('mobilefeatures')
    const businessElement = document.getElementById('mobilebusiness')
    const distElement1 = document.getElementById('distribID')
    const distElement2 = document.getElementById('distributorList')
    const distElement3 = document.getElementById('distributorHowItWorks')
    const distElement4 = document.getElementById('distributorButton')
    const elements = [
        homeElement,
        aboutElement,
        featuresElement,
        businessElement,
        distElement1,
        distElement2,
        distElement3,
        distElement4,
    ]

    useEffect(() => {
        images.forEach((image) => {
            const img = new Image()
            img.src = image.src
            img.onload = () => {
                image.loaded = true
            }
        })
        setIsLoading(false)
    })

    useEffect(() => {
        const glowing = document.getElementById('filter3_f_775_87728')
        if (location.pathname.includes('business') || location.pathname.includes('become_distibutor')) {
            glowing.setAttribute('width', '0')
        } else {
            glowing.setAttribute('width', '606')
        }
    })

    const actionHandler = (direction) => {
        if (location.pathname === '/') {
            if (direction === 'next' && current < elements.length - 1) {
                setCurrent((prev) => prev + 1)
            } else if (direction === 'prev' && current > 0) {
                setCurrent((prev) => prev - 1)
            }
        }
    }
    useEffect(() => {
        elements[current]?.scrollIntoView({ behavior: 'smooth' })
    }, [current])
    useEffect(() => {
        const rootCurrent = rootRef.current
        let posInit = 0
        let posX1 = 0
        let posX2 = 0
        let posY1 = 0
        let posY2 = 0
        let posFinal = 0
        let posThreshhold = 100

        const swipeStart = (event) => {
            posInit = posY1 = event.clientY ?? event.touches[0].clientY
            posX1 = event.clientX ?? event.touches[0].clientX

            document.addEventListener('touchmove', swipeAction)
            document.addEventListener('touchend', swipeEnd)
        }
        const swipeAction = (event) => {
            posY2 = posY1 - (event.clientY ?? event.touches[0].clientY)
            posY1 = event.clientY ?? event.touches[0].clientY

            posX2 = posX1 - event.clientX ?? event.touches[0].clientX
            posX1 = event.clientX ?? event.touches[0].clientX
        }
        const swipeEnd = () => {
            posFinal = posInit - posY1

            if (Math.abs(posFinal) > posThreshhold) {
                if (posInit < posY1) {
                    actionHandler('prev')
                } else if (posInit > posY1) {
                    actionHandler('next')
                }
            }

            document.removeEventListener('touchmove', swipeAction)
            document.removeEventListener('touchend', swipeEnd)
        }

        rootCurrent.addEventListener('touchstart', swipeStart)

        return () => {
            rootCurrent.removeEventListener('touchstart', swipeStart)
        }
    }, [actionHandler])
    return (
        <div
            ref={rootRef}
            className={cls.wrapper}
        >
            {!location.pathname.split('/').includes('business') ||
                (!location.pathname === 'become_distributor' && (
                    <img
                        alt="distributo"
                        src={bgImg}
                        className={cls.backgroundImg}
                    />
                ))}

            <div className={cls.MobileMain}>
                <img
                    alt="logo"
                    src={logo}
                    className={cls.logo}
                />
                {location.pathname === '/' && (
                    <>
                        <MobileHome id="mobilehome" />
                        <MobileAbout id="mobileabout" />
                        <MobileFeatures id="mobilefeatures" />
                        <MobileBusiness id="mobilebusiness" />
                        <MobileDistr id="distribID" />
                    </>
                )}
                {location.pathname.split('/').includes('business') && <>{children}</>}
                <MobileMenu
                    open={open}
                    setOpen={setOpen}
                    setCurrent={setCurrent}
                    current={current}
                />
            </div>
            <ContactForm
                open={open}
                setOpen={setOpen}
            />
            {isLoading && (
                <div className={cls.loader}>
                    <div className={cls.spinner} />
                </div>
            )}
        </div>
    )
}
