import React, { useState } from "react"
import { ReactComponent as GlowLine } from "@/shared/assets/blueGlowLine.svg"
import { ReactComponent as Rocket } from "@/shared/assets/rocket.svg"
import sixplayers from "@/shared/assets/sixplayer.png"
import oneclick from "@/shared/assets/onclickDesktop.png"
import gameplay from "@/shared/assets/gameplay.png"
import beagraphics from "@/shared/assets/beagraphics.png"
import cls from "./becomepart.module.scss"

import { classNames } from "@/shared/lib/utils/classNames"
import { ContactForm } from "../ContactForm/ContactForm"
import { Carousel } from "@/shared/ui/Carousel/Carousel"
import useResize from "@/shared/lib/hooks/useResize"
export const BecomePart = () => {
    const [modalOpen, setModal] = useState(false)
    const windowWidth = useResize()
    return (
        <>
            <article
                id='home'
                className={cls.BecomePart}>
                <svg
                    className={cls.bgGlow}
                    xmlns='http://www.w3.org/2000/svg'
                    width='1823'
                    height='1240'
                    viewBox='0 0 1823 1240'
                    fill='none'>
                    <g filter='url(#filter0_f_546_6135)'>
                        <path
                            d='M1561.16 619.78C1561.16 723.235 1594.44 869.218 1472.59 935.664C1356.89 998.755 1090.6 984.964 917.447 984.964C762.737 984.964 445.923 1007.8 334.912 956.205C190.828 889.234 273.736 733.697 273.736 619.78C273.736 516.326 213.063 359.844 334.911 293.398C450.607 230.307 744.295 254.597 917.447 254.597C1090.6 254.597 1377.44 230.307 1493.13 293.398C1614.98 359.844 1561.16 516.326 1561.16 619.78Z'
                            fill='url(#paint0_linear_546_6135)'
                        />
                    </g>
                    <defs>
                        <filter
                            id='filter0_f_546_6135'
                            x='1.90735e-06'
                            y='0'
                            width='1823'
                            height='1240'
                            filterUnits='userSpaceOnUse'
                            color-interpolation-filters='sRGB'>
                            <feFlood
                                flood-opacity='0'
                                result='BackgroundImageFix'
                            />
                            <feBlend
                                mode='normal'
                                in='SourceGraphic'
                                in2='BackgroundImageFix'
                                result='shape'
                            />
                            <feGaussianBlur
                                stdDeviation='125'
                                result='effect1_foregroundBlur_546_6135'
                            />
                        </filter>
                        <linearGradient
                            id='paint0_linear_546_6135'
                            x1='485.748'
                            y1='297.034'
                            x2='1428.28'
                            y2='984.655'
                            gradientUnits='userSpaceOnUse'>
                            <stop stop-color='#D505FF' />
                            <stop
                                offset='1'
                                stop-color='#724AFF'
                            />
                        </linearGradient>
                    </defs>
                </svg>

                <div className={cls.rightSection}>
                    <div className={cls.headline}>
                        <h1>become</h1>
                        <h2>a part</h2>
                        <h2>of stellar bets</h2>
                    </div>
                    <div className={cls.transText}>
                        <h3>
                            Bring fun and excitement in just
                            a couple of clicks.
                        </h3>
                        <p>
                            Contact us or leave your
                            contacts and we will get back to
                            you
                        </p>
                    </div>
                    <button
                        onClick={() => setModal(true)}
                        className={cls.contactButton}>
                        <h3>CONTACT US</h3>
                        <h3>
                            AND BECOME <b>STELLAR</b>
                        </h3>
                        <GlowLine
                            className={cls.glowLine}
                        />
                        <Rocket className={cls.rocket} />
                    </button>
                </div>
                <div className={cls.leftSection}>
                    <Carousel
                        withArrow={windowWidth > 820}
                        withPagination>
                        <div
                            className={classNames(
                                cls.gameplay,
                                [cls.sliderItem]
                            )}>
                            <div className={cls.headline}>
                                <h1>ONE CLICK</h1>
                                <h2>INSTALLATION</h2>
                                <p>
                                    Easy setup in just a few
                                    clicks—simple enough for
                                    anyone to get started
                                </p>
                            </div>
                            <img alt="backgroundImg" src={oneclick} />
                        </div>
                        <div
                            className={classNames(
                                cls.oneclick,
                                [cls.sliderItem]
                            )}>
                            <div className={cls.headline}>
                                <h1>BEAUTIFUL</h1>
                                <h2>GRAPHICS</h2>
                                <p>
                                    A gripping experience
                                    that keeps players
                                    engaged and coming back
                                    for more, always hungry
                                    for the next game
                                </p>
                            </div>
                            <img alt="beagraphics" src={beagraphics} />
                        </div>
                        <div
                            className={classNames(
                                cls.beagraphics,
                                [cls.sliderItem]
                            )}>
                            <div className={cls.headline}>
                                <h1>EXCITING</h1>
                                <h2>GAMEPLAY</h2>
                                <p>
                                    Addictive gameplay and
                                    captivating mechanics
                                    will keep players coming
                                    back
                                </p>
                            </div>
                            <img alt="gameplay" src={gameplay} />
                        </div>
                        <div
                            className={classNames(
                                cls.sixplayers,
                                [cls.sliderItem]
                            )}>
                            <div className={cls.headline}>
                                <h1>UP TO</h1>
                                <h2>6 PLAYERS</h2>
                                <p>
                                    New killer feature
                                    allows to engage up to 6
                                    players at the same time
                                    on the same screen
                                </p>
                            </div>
                            <img alt="sixplayers" src={sixplayers} />
                        </div>
                    </Carousel>
                </div>
                <svg
                    className={cls.largeBgGlow}
                    width='1823'
                    height='1240'
                    viewBox='0 0 1823 1240'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'>
                    <g filter='url(#filter0_f_92_6)'>
                        <path
                            d='M1561.16 619.78C1561.16 723.235 1594.44 869.218 1472.59 935.664C1356.89 998.755 1090.6 984.964 917.447 984.964C762.737 984.964 445.923 1007.8 334.912 956.205C190.828 889.234 273.736 733.697 273.736 619.78C273.736 516.326 213.063 359.844 334.911 293.398C450.607 230.307 744.295 254.597 917.447 254.597C1090.6 254.597 1377.44 230.307 1493.13 293.398C1614.98 359.844 1561.16 516.326 1561.16 619.78Z'
                            fill='url(#paint0_linear_92_6)'
                        />
                    </g>
                    <defs>
                        <filter
                            id='filter0_f_92_6'
                            x='0'
                            y='0'
                            width='1823'
                            height='1240'
                            filterUnits='userSpaceOnUse'
                            color-interpolation-filters='sRGB'>
                            <feFlood
                                flood-opacity='0'
                                result='BackgroundImageFix'
                            />
                            <feBlend
                                mode='normal'
                                in='SourceGraphic'
                                in2='BackgroundImageFix'
                                result='shape'
                            />
                            <feGaussianBlur
                                stdDeviation='125'
                                result='effect1_foregroundBlur_92_6'
                            />
                        </filter>
                        <linearGradient
                            id='paint0_linear_92_6'
                            x1='485.748'
                            y1='297.034'
                            x2='1428.28'
                            y2='984.655'
                            gradientUnits='userSpaceOnUse'>
                            <stop stop-color='#D505FF' />
                            <stop
                                offset='1'
                                stop-color='#724AFF'
                            />
                        </linearGradient>
                    </defs>
                </svg>
            </article>

            <ContactForm
                open={modalOpen}
                setOpen={setModal}
            />
        </>
    )
}
