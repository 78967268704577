import React, { useEffect, useState } from "react"
import logo from "@/shared/assets/logo_stellar_bet.webp"
import cls from "./header.module.scss"
import { ReactComponent as BurgerMenu } from "@/shared/assets/burgerIcon.svg"
import { ReactComponent as CloseMenu } from "@/shared/assets/closeBtn.svg"
import { useScrollDirection } from "@/shared/lib/hooks/useSrollDirection"
import { classNames } from "@/shared/lib/utils/classNames"
import { ContactForm } from "../ContactForm/ContactForm"
import useResize from "@/shared/lib/hooks/useResize"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { useLocation, useNavigate } from "react-router-dom"
export const Header = () => {
    const scrollDirection = useScrollDirection()
    const [open, setOpen] = useState()
    const [menuOpen, setMenu] = useState(false)
    const windowWidth = useResize()
    const navigate = useNavigate()
    const location = useLocation()
    useEffect(() => {
        document.addEventListener("scroll", () =>
            setMenu(false)
        )

        return () => {
            document.removeEventListener("scroll", () =>
                setMenu(false)
            )
        }
    })
    const navigationItem = (content, href, offset) => {
        if (location.pathname !== "/") {
            return (
                <span
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("/")}>
                    {content}
                </span>
            )
        } else {
            return (
                <AnchorLink
                    href={href}
                    offset={() => offset}>
                    {content}
                </AnchorLink>
            )
        }
    }
    const desktopHeader = () => (
        <div className={cls.HeaderContainer}>
            {navigationItem(
                <img alt="logo" src={logo} />,
                "#home",
                300
            )}
            <nav className={cls.navigation}>
                <p onClick={() => navigate("/distributor")}>
                    Become a distributor
                </p>
                {navigationItem(<p>Home</p>, "#home", 300)}
                {navigationItem(
                    <p>Features</p>,
                    "#features",
                    100
                )}
                {navigationItem(
                    <p>Contacts</p>,
                    "#contacts",
                    100
                )}
            </nav>
            <button
                onClick={() => setOpen(true)}
                className={cls.contactusButton}>
                <p>contact us</p>
            </button>
        </div>
    )

    const mobileHeader = () => (
        <>
            <div className={cls.MobileHeaderContainer}>
                <AnchorLink
                    href='#home'
                    offset={() => 300}>
                    <img alt="some problems"
                        src={logo}
                        className={cls.logo}
                    />
                </AnchorLink>
                {menuOpen ? (
                    <CloseMenu
                        onClick={() => setMenu(false)}
                    />
                ) : (
                    <BurgerMenu
                        className={cls.BurgerIcon}
                        onClick={() => setMenu(true)}
                    />
                )}
            </div>
            <div
                className={classNames(cls.menu, [], {
                    [cls.close]: menuOpen === false,
                })}>
                <AnchorLink
                    href='#home'
                    offset={() => 300}>
                    <p>Home</p>
                </AnchorLink>
                <AnchorLink
                    href='#features'
                    offset={() => 100}>
                    <p>Features</p>
                </AnchorLink>
                <AnchorLink
                    href='#contacts'
                    offset={() => 100}>
                    <p>Contacts</p>
                </AnchorLink>
                <button
                    onClick={() => setOpen(true)}
                    className={cls.contactusButton}>
                    <p>contact us</p>
                </button>
            </div>
        </>
    )
    const render = () => {
        if (windowWidth > 1023) {
            return desktopHeader()
        } else {
            return mobileHeader()
        }
    }
    return (
        <>
            <header
                className={classNames(
                    cls.headerWrapper,
                    [],
                    {
                        [cls.close]:
                            scrollDirection === "down",
                    }
                )}>
                {render()}
            </header>
            <ContactForm
                open={open}
                setOpen={setOpen}
            />
        </>
    )
}
