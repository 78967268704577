import React, { Children, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { ReactComponent as Arrow } from '@/shared/assets/leftArrow.svg'
import cls from './carousel.module.scss'
import { classNames } from '@/shared/lib/utils/classNames'

export const Carousel = ({ children, withArrow, withPagination, autoPlay, width, slideClass, className }) => {
    const [current, setCurrent] = useState(1)
    const [translateX, setTranslateX] = useState(0)
    const sliderRef = useRef()
    const rootRef = useRef()
    const intervalRef = useRef(null)

    const actionHandler = useCallback(
        (mode, selected) => {
            sliderRef.current.style.transitionDuration = '500ms'
            if (mode === 'prev') {
                if (current <= 1) {
                    setTranslateX(0)
                    setCurrent(children.length)
                } else {
                    setTranslateX(sliderRef.current.clientWidth * (current - 1))
                    setCurrent((prev) => --prev)
                }
            } else if (mode === 'next') {
                if (current >= children.length) {
                    setTranslateX(sliderRef.current.clientWidth * (children.length + 1))
                    setCurrent(1)
                } else {
                    setTranslateX(sliderRef.current.clientWidth * (current + 1))
                    setCurrent((prev) => ++prev)
                }
            } else if (mode === 'select') {
                setCurrent(selected)
                setTranslateX(sliderRef.current.clientWidth * (current + 1))
            }
        },
        [current, children]
    )

    useEffect(() => {
        const rootCurrent = rootRef.current
        let xStart, xMove, xFinal
        const swipeStart = (event) => {
            xStart = event.clientX ?? event.touches[0].clientX
            document.addEventListener('touchmove', swipeAction)
            document.addEventListener('touchend', swipeEnd)
        }
        const swipeAction = (event) => {
            xMove = event.clientX ?? event.touches[0].clientX - xStart
        }
        const swipeEnd = () => {
            xFinal = xMove
            document.removeEventListener('touchmove', swipeAction)
            document.removeEventListener('touchend', swipeEnd)
            if (xFinal - xStart > 50) {
                actionHandler('prev')
            }
            if (xFinal - xStart < -10) {
                actionHandler('next')
            }
        }

        rootCurrent.addEventListener('touchstart', swipeStart)

        return () => {
            rootCurrent.removeEventListener('touchstart', swipeStart)
        }
    }, [actionHandler])

    useEffect(() => {
        const transitionEnd = () => {
            if (current <= 1) {
                sliderRef.current.style.transitionDuration = '0ms'
                setTranslateX(sliderRef.current.clientWidth * current)
            }

            if (current >= children.length) {
                sliderRef.current.style.transitionDuration = '0ms'
                setTranslateX(sliderRef.current.clientWidth * children.length)
            }
        }

        document.addEventListener('transitionend', transitionEnd)

        return () => {
            document.removeEventListener('transitionend', transitionEnd)
        }
    }, [current, children])

    useEffect(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current)
        }
        intervalRef.current = setInterval(() => {
            if (autoPlay) {
                actionHandler('next')
            }
        }, 5000)

        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current)
            }
        }
    }, [actionHandler])

    const slides = useMemo(() => {
        if (children.length > 1) {
            let items = Children.map(children, (item, index) => (
                <div
                    id={item.index}
                    className={classNames(cls.slide, [slideClass])}
                >
                    {item}
                </div>
            ))

            return [
                <div
                    key={children.length + 1}
                    className={classNames(cls.slide, [slideClass])}
                >
                    {children[children.length - 1]}
                </div>,
                ...items,
                <div
                    key={children.length + 2}
                    className={classNames(cls.slide, [slideClass])}
                >
                    {children[0]}
                </div>,
            ]
        }

        return <div className={classNames(cls.slide, [slideClass])}>{children[0]}</div>
    }, [children])

    useLayoutEffect(() => {
        setTranslateX(sliderRef.current.clientWidth * current)
    }, [])
    return (
        <section
            ref={rootRef}
            className={classNames(cls.Root, [className])}
            style={{ width: width }}
        >
            <div
                ref={sliderRef}
                className={cls.Container}
                style={{
                    transform: `translate3D(${-translateX}px, 0px, 0px)`,
                }}
            >
                {slides}
            </div>
            {withArrow && (
                <>
                    <Arrow
                        onClick={() => actionHandler('prev')}
                        className={classNames(cls.leftBtn, [cls.btn])}
                    />
                    <Arrow
                        onClick={() => actionHandler('next')}
                        className={classNames(cls.rightBtn, [cls.btn])}
                    />
                </>
            )}
            {withPagination && (
                <div className={cls.pagination}>
                    {Array.from({ length: children.length }, (_, index) => (
                        <div
                            key={index}
                            className={classNames(cls.paginationItem, [], {
                                [cls.active]: index === current - 1,
                            })}
                            onClick={() => actionHandler('select', index + 1)}
                        />
                    ))}
                </div>
            )}
        </section>
    )
}
