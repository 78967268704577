import React, { useState } from 'react'
import logo from '@/shared/assets/logo_stellar_bet.webp'
import { ReactComponent as Rocket } from '@/shared/assets/rocket.svg'
import { ReactComponent as ContactButton } from '@/shared/assets/endingContactButton.svg'
import slide1 from '@/shared/assets/slide1.webp'
import slide2 from '@/shared/assets/slide2.webp'
import slide3 from '@/shared/assets/slide3.webp'
import cls from './ending.module.scss'
import { ContactForm } from '../ContactForm/ContactForm'
import { Carousel } from '@/shared/ui/Carousel/Carousel'

export const Ending = () => {
    const [open, setOpen] = useState(false)
    return (
        <article
            id="contacts"
            className={cls.Ending}
        >
            <Rocket className={cls.Rocket} />
            <div className={cls.heading}>
                <h1>BECOME A PART</h1>
                <div className={cls.textLogo}>
                    <h3>OF</h3>
                    <img
                        src={logo}
                        alt="Stellar Bets"
                    />
                </div>
            </div>
            <div className={cls.EndingFrame}>
                <Carousel
                    width={'100%'}
                    autoPlay
                    slideClass={cls.slideClass}
                >
                    <img
                        alt="slide1"
                        src={slide1}
                    />
                    <img
                        alt="slide2"
                        src={slide2}
                    />
                    <img
                        alt="slide3"
                        src={slide3}
                    />
                </Carousel>
            </div>
            <ContactButton
                onClick={() => setOpen(true)}
                className={cls.contactButton}
            />

            <svg
                className={cls.bgGlow}
                width="1927"
                height="1376"
                viewBox="0 0 1927 1376"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g filter="url(#filter0_f_102_24)">
                    <path
                        d="M1665 687.001C1665 801.448 1709.31 969.715 1594.5 1047.5C1467.75 1133.38 1172.12 1123 971 1123C786.496 1123 449.808 1142.78 325.5 1069C191.573 989.514 277 811.884 277 687.001C277 544.527 185.776 403.562 354 324.001C470.092 269.095 814.497 251.001 971 251.001C1164.82 251.001 1468.59 243.512 1594.5 324.001C1717.58 402.678 1665 567.972 1665 687.001Z"
                        fill="url(#paint0_linear_102_24)"
                    />
                </g>
                <defs>
                    <filter
                        id="filter0_f_102_24"
                        x="0.154785"
                        y="0.816406"
                        width="1926.01"
                        height="1374.59"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                    >
                        <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                        />
                        <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="BackgroundImageFix"
                            result="shape"
                        />
                        <feGaussianBlur
                            stdDeviation="125"
                            result="effect1_foregroundBlur_102_24"
                        />
                    </filter>
                    <linearGradient
                        id="paint0_linear_102_24"
                        x1="505.574"
                        y1="301.667"
                        x2="1591.35"
                        y2="1016.97"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#C64AFF" />
                        <stop
                            offset="1"
                            stop-color="#FF05AC"
                        />
                    </linearGradient>
                </defs>
            </svg>

            <ContactForm
                open={open}
                setOpen={setOpen}
            />
        </article>
    )
}
