import React from 'react'
import { ReactComponent as Bag } from '@/Mobile/shared/assets/bag.svg'
import { ReactComponent as Rocket } from '@/Mobile/shared/assets/rocket-menu.svg'
import { ReactComponent as Gamepad } from '@/Mobile/shared/assets/gamepad.svg'
import { ReactComponent as Stars } from '@/Mobile/shared/assets/stars.svg'
import { ReactComponent as Coin } from '@/Mobile/shared/assets/Coin.svg'
import { ReactComponent as RocketButton } from '@/Mobile/shared/assets/contButton.svg'
import cls from './MobileMenu.module.scss'
import { classNames } from '@/shared/lib/utils/classNames'
import { useLocation, useNavigate } from 'react-router-dom'

export const MobileMenu = ({ open, setOpen, setCurrent, current }) => {
    const location = useLocation()
    const navigate = useNavigate()
    const setPage = (index) => {
        if (location.pathname !== '/') {
            navigate('/')
            setTimeout(() => {
                setCurrent(index)
            }, 200);
        } else {
            setCurrent(index)
        }
    }
    return (
        <div className={cls.MobileMenu}>
            <RocketButton
                onClick={() => setOpen(true)}
                className={cls.RocketButton}
            />
            <div
                onClick={() => setPage(0)}
                className={cls.navItem}
            >
                <Rocket
                    className={classNames(cls.svg, [], {
                        [cls.active]: current === 0,
                    })}
                />
                <span>Home</span>
            </div>
            <div
                onClick={() => setPage(1)}
                className={cls.navItem}
            >
                <Gamepad
                    className={classNames(cls.svg, [], {
                        [cls.active]: current === 1,
                    })}
                />
                <span>About</span>
            </div>
            <div
                onClick={() => setPage(2)}
                className={cls.navItem}
            >
                <Stars
                    className={classNames(cls.svg, [], {
                        [cls.active]: current === 2,
                    })}
                />
                <span>Features</span>
            </div>
            <div
                onClick={() => setPage(3)}
                className={cls.navItem}
            >
                <Bag
                    className={classNames(cls.svg, [], {
                        [cls.active]: current === 3,
                    })}
                />
                <span>Business</span>
            </div>
            <div
                onClick={() => setPage(4)}
                className={classNames(cls.navItem, [cls.Coin])}
            >
                <Coin
                    className={classNames(cls.svg, [], {
                        [cls.active]: current === 4,
                    })}
                />
                <span>Become a Distributor</span>
            </div>
        </div>
    )
}
